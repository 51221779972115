import React, { useRef, useEffect } from 'react';

import SEO from "../components/seo"
import { graphql } from 'gatsby';
import { Layout } from '../layout/Layout';
import { Banner, ContactBanner } from '../components/common';
import Benefits from '../components/modules/evaluaciones/Benefits';
import sanitizeHtml from 'sanitize-html';
import starOutline from '../images/svg/star-outline.svg';
import starSolid from '../images/svg/star-solid.svg';

const CalificacionSocial = ({ data, pageContext }) => {
  const starsRef = useRef(null);

  useEffect(() => {
    const starList = [...starsRef.current.children];
    starList.forEach((element, index) => {
      element.className = 'stars-paragraph';

      const starsContainer = document.createElement('span');
      starsContainer.className = 'stars';

      const starsNumber = starList.length - index;
      for (let i = 0; i < starList.length; i++) {
        const star = document.createElement('img');
        star.setAttribute('src', i < starsNumber ? starSolid : starOutline);
        starsContainer.appendChild(star);
      }

      element.prepend(starsContainer);
    });

    return () => {
      [...document.getElementsByClassName('stars')].forEach(element => {
        element.remove();
      });
    };
  }, []);

  const { banner_image } = data.directus.social_rating;
  const {
    top_banner_text,
    details_title,
    analysis_description,
    analysis_cards,
    ratings_description,
    ratings_stars,
    details_message,
    benefits,
    contact_banner_text,
    contact_banner_button,
  } = data.directus.social_rating_translations_by_id;

  const cmsURL = data.site.siteMetadata.cmsUrl

  return (
    <Layout pageId="social" currentLanguage={pageContext.languageCode}>
      <Banner
        innerText={top_banner_text}
        image={`${cmsURL}/assets/${banner_image.id}`}
      />
      <section className="details">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto details__container">
              <div className="details__content">
                <h2>{details_title}</h2>
                {analysis_description
                  ?.split('\n')
                  .filter(p => p !== '')
                  .map(p => (
                    <p key={p}>{p}</p>
                  ))}
                <div className="analysis-cards">
                  {analysis_cards.map(card => {
                    return (
                      <div className="card">
                        <h3>{card.title}</h3>
                        {card.body
                          .split('\n')
                          .filter(p => p !== '')
                          .map(p => (
                            <p key={p}>{p}</p>
                          ))}
                      </div>
                    );
                  })}
                </div>
                {ratings_description
                  ?.split('\n')
                  .filter(p => p !== '')
                  .map(p => (
                    <p key={p}>{p}</p>
                  ))}
                <div
                  ref={starsRef}
                  className="ratings-stars"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(ratings_stars),
                  }}
                />
                {details_message && (
                  <p className="details__msg">
                    <span>{details_message}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Benefits content={benefits} link={false} />
      <ContactBanner
        innerText={contact_banner_text}
        buttonText={contact_banner_button}
        to={`${pageContext.basePath}/contact`}
        linkState={{ subject: 'Calificación Social' }}
      />
    </Layout>
  );
};

export const query = graphql`
  query SocialPageQuery($translationId: ID!) {
    site {
      siteMetadata {
        cmsUrl
      }
    }
    directus {
      social_rating {
        banner_image {
          id
        }
      }
      social_rating_translations_by_id(id: $translationId) {
        top_banner_text
        details_title
        analysis_description
        analysis_cards
        ratings_description
        ratings_stars
        details_message
        benefits
        contact_banner_text
        contact_banner_button
      }
    }
  }
`;

export default CalificacionSocial;

export const Head = () => <SEO title="Social Rating - MicroRate" />;
